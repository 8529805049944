import { registerApplication, start, LifeCycles } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from 'single-spa-layout';

const routes = constructRoutes(document.querySelector('#single-spa-layout'));
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});
const layoutEngine = constructLayoutEngine({ routes, applications });

// registerApplication({
//   name: "@kbrain/kchat",
//   app: () => System.import<LifeCycles>("@kbrain/kchat"),
//   activeWhen: ["/"],
// });
  
// registerApplication({
//   name: "@kbrain/kauth",
//   app: () => System.import<LifeCycles>("@kbrain/kauth"),
//   activeWhen: ["/"],
// });
  
// registerApplication({
//   name: "@kbrain/kbrain-chat",
//   app: () => System.import<LifeCycles>("@kbrain/kbrain-chat"),
//   activeWhen: ["/"],
// });
  
applications.forEach(registerApplication);
start();


// start({
//   urlRerouteOnly: true,
// });
